var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vod-status-indicator", class: _vm.variant },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover.noninteractive",
              modifiers: { hover: true, noninteractive: true }
            }
          ],
          class: _vm._status,
          attrs: { title: _vm.variant === "short" ? _vm.title : undefined }
        },
        [
          _c(_vm.icon, { tag: "component" }),
          _vm.variant === "long"
            ? _c("span", [_vm._v(_vm._s(_vm.title))])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }